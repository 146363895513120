<!-- 柱子图 -->
<template>
  <div class="bar-container">
    <div style="width: 100%;">
      <!-- 图表 -->
      <div class="body">
        <div style="display:flex;flex-direction: column;width: 100%;">
          <div
            :style="
              `overflow: hidden;height:${value == 1 ? '320px' : 0};width: 100%;`
            "
          >
            <div :id="barMain" style="height: 320px;width: 100%;"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import引入的组件需要注入到components中才能使用

export default {
  name: "",
  components: {},
  //      数据         类名字    x轴数据
  props: ["homeData", "barMain", "xData"],
  data() {
    return {
      value: "1",
      option: {
        width: "90%",
        color: "#1890ff",
        tooltip: {
          trigger: "axis",
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow" // 默认为直线，可选为：'line' | 'shadow'
          }
        },
        xAxis: {
          type: "category",
          data: [],
          axisTick: {
            //---坐标轴 刻度
            show: false //---是否显示
          },
          axisLine: {
            //隐藏刻度线
            show: false
          },
          axisLabel: {
            //X轴刻度坐标文字样式
            interval: 0, //设置为0强制显示所有标签，如果设置为1，表示隔一个标签显示一个标签
            textStyle: {
              fontSize: 14,
              color: "#999"
            }
          }
        },
        yAxis: {
          type: "value",
          axisTick: {
            //---坐标轴 刻度
            show: false //---是否显示
          },
          axisLine: {
            //隐藏刻度线
            show: false
          },
          axisLabel: {
            //Y轴刻度坐标文字样式
            textStyle: {
              fontSize: 14,
              color: "#999"
            }
            // formatter: this.barMain[0] == "barMain2" ? "{value} %" : "{value} W"
          }
        },
        // legend: {
        //   show: true,
        //   data: [], //图例，series里name
        //   right: "100"
        // },
        grid: {
          left: "9%",
          right: "12%",
          bottom: "9%",
          containLabel: false //---grid 区域是否包含坐标轴的刻度标签
        },
        series: [
          {
            data: [],
            type: "bar",
            barWidth: 40 //柱图宽度
          }
        ]
      },
      xx: [],
      yy: []
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    drawBar() {
      // echarts柱子图
      let myChart = this.$echarts.init(
        document.getElementById(this.barMain)
      );
      this.option.xAxis.data = this.xData;
      this.option.series[0].data = this.homeData;
      myChart.setOption(this.option);

    //   let x = this.option.xAxis.data; //X轴
    //   let totData = this.option.series[0].data;
      //   let totData = []; //数据
    },
    echartsLine(echartsY, items, num) {
      this.option.series = echartsY;
      this.option.legend.data = items;

      let myChart = this.$echarts.init(
        document.getElementById(this.barMain[num])
      );
      myChart.setOption(this.option);
    }
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this.drawBar();
  }
};
</script>

<style lang="less" scoped>
.bar-container {
  width: 100%;
  .body {
    display: flex;
    width: 640px;
  }
  .barMain {
    display: inline-block;
    width: 640px;
    height: 320px;
  }
  .shopNum {
    box-sizing: border-box;
    display: inline-block;
    width: 405px;
    margin-left: 70px;
    padding: 0 0 32px 32px;
    .publicTit {
      color: rgba(16, 16, 16, 100);
      font-size: 20px;
      margin-bottom: 32px;
    }
    .con {
      li {
        margin-top: 16px;
        box-sizing: border-box;
        height: 48px;
        background: #f2f9ff;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 12px 16px;
        .logo {
          margin-right: 16px;
        }
      }
    }
  }
  .shopNum2 {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    width: 376px;
    margin-left: 70px;
    background: #f2f9ff;
    border-radius: 8px;
    padding: 32px;
    li {
      width: 312px;
      height: 120px;
      border-radius: 8px;
      background-color: rgba(255, 255, 255, 100);
      &:nth-child(-n + 2) {
        margin-bottom: 32px;
      }
      .left {
        display: inline-block;
        width: 32px;
        height: 120px;
        text-align: center;
        line-height: 32px;
        border-radius: 8px 0px 0px 8px;
        color: #fff;
        font-size: 14px;
        writing-mode: vertical-rl;
        background-color: rgba(24, 144, 255, 100);
      }
      .right {
        display: flex;
        flex-direction: column;
        margin-left: 32px;
        .mon {
          color: rgba(51, 51, 51, 100);
          font-size: 14px;
        }
        /deep/.money {
          text-decoration: underline;
          text-align: left;
          span {
            color: rgba(24, 144, 255, 100);
            font-size: 28px;
          }
        }
        .money3 {
          display: block;
          color: rgba(51, 51, 51, 100);
          font-size: 28px;
        }
      }
      .left2 {
        display: inline-block;
        width: 32px;
        height: 120px;
        text-align: center;
        line-height: 32px;
        border-radius: 8px 0px 0px 8px;
        color: #fff;
        font-size: 14px;
        writing-mode: vertical-rl;
        background-color: #ff4d4f;
      }
      .right2 {
        display: flex;
        flex-direction: column;
        margin-left: 32px;
        .mon {
          color: rgba(51, 51, 51, 100);
          font-size: 14px;
        }
        /deep/.money {
          text-align: left;
          text-decoration: underline;
          text-decoration-color: #ff4d4f;
          span {
            color: #ff4d4f;
            font-size: 28px;
          }
        }
      }
    }
  }

  .charts {
    width: 200px;
    /deep/.el-input__inner {
      color: #1890ff;
      background: #f2f9ff;
      border: none;
    }
    /deep/ .el-select__caret {
      color: #1890ff;
    }
  }
}
</style>
